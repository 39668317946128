.slick-slide > div {
  margin: 0px 20px 0px 20px;
  padding: 0px 0px 20px 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}
html {
  -webkit-tap-highlight-color: transparent;
}

.image-container {
  height: calc(100vh - calc(100vh - 100%));
  background-color: red;
}

/* Infinite scrolling package wrapper needs to be manually set here.
  className and style={} seems to not work.
*/
.infinite-scroll-component__outerdiv {
  width: 100%;
}
.infinite-scroll-component {
  width: 100% !important;
}
.slick-center {
  margin-left: auto;
  margin-right: auto;
}

.react-player {
  position: relative;
}
.react0 .rotated {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vh;
  rotate: calc(90);
  object-fit: contain;
}

/* ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #eeeeee radial-gradient(circle, transparent 1%, #eeeeee 1%)
    center/15000%;
}
.ripple:active {
  background-color: #cacaca;
  background-size: 100%;
  transition: background 0s;
}
.Toastify__progress-bar-theme--light {
  background: #b827fc;
}

/* Sign in verification input styles */
.code-input {
  border: 4px solid transparent;
  border-image: linear-gradient(
    to bottom right,
    #b827fc 0%,
    #2c90fc 25%,
    #fd1892 100%
  );
  border-image-slice: 1;
  width: 200px;
  background-position: center;
  transition: background 0.8s;
  color: black;
}
/* .code-input:hover {
  background: #eeeeee radial-gradient(circle, transparent 1%, #eeeeee 1%) center/15000%;
} */
.code-input:active {
  background: #e6e6e6 radial-gradient(circle, transparent 1%, #eeeeee 1%)
    center/15000%;
  background-size: 100%;
  /* --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 ); */
}

/* button style */
.ripple {
  cursor: pointer;
  color: black;
  outline: none;
}

/* text shadow className */
.text-shadow-text {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.92);
}
.textareadiv {
  line-height: 15px;
  transition: width 0.25s;
  resize: none;
  overflow: auto;
  max-height: 80px;
  min-height: 45px;
}
